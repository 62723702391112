import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-33d79b4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "roleCard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "formRef",
      model: _ctx.form,
      rules: _ctx.rules,
      "label-width": "135px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('role.form.name'),
          prop: "name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              onInput: _cache[0] || (_cache[0] = e => _ctx.form.name = _ctx.valid(e)),
              modelValue: _ctx.form.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.name) = $event)),
              maxlength: "100",
              placeholder: _ctx.$t('role.form.name_placeholder'),
              disabled: _ctx.disabled
            }, null, 8, ["modelValue", "placeholder", "disabled"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('role.form.desc'),
          prop: "desc"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              type: "textarea",
              onInput: _cache[2] || (_cache[2] = e => _ctx.form.desc = _ctx.valid(e)),
              modelValue: _ctx.form.desc,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.desc) = $event)),
              maxlength: "100",
              placeholder: "请输入说明",
              disabled: _ctx.disabled
            }, null, 8, ["modelValue", "disabled"])
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}