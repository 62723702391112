import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4af3d7ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "roleMenu" }
const _hoisted_2 = { class: "custom-tree-node" }
const _hoisted_3 = { style: {"width":"200px","font-size":"16px","font-weight":"bold","color":"var(--el-text-color-primary)"} }
const _hoisted_4 = { style: {"flex":"1"} }
const _hoisted_5 = {
  key: 0,
  style: {"display":"flex","justify-content":"flex-end"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_scrollbar, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topMenuTree.topMenus, (menu) => {
                  return (_openBlock(), _createBlock(_component_el_tag, {
                    key: menu.menuId,
                    class: _normalizeClass(_ctx.isActive(menu.menuId)?'active':''),
                    effect: "plain",
                    size: "large",
                    onClick: ($event: any) => (_ctx.tagClick(menu.menuId))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(menu.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["class", "onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_main, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_tree, {
              ref: "treeRef",
              data: _ctx.treeData,
              "show-checkbox": "",
              "node-key": "id",
              props: _ctx.defaultProps,
              "default-expand-all": "",
              "check-on-click-node": true,
              "expand-on-click-node": false,
              onCheckChange: _ctx.nodeCheckChange,
              onNodeClick: _ctx.nodeClickHandler,
              onCurrentChange: _ctx.curCheckChange
            }, {
              default: _withCtx(({ node, data }) => [
                _createElementVNode("span", _hoisted_2, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(node.label), 1),
                  _createElementVNode("span", _hoisted_4, [
                    (data.parentId=='-')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createVNode(_component_el_button_group, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                plain: "",
                                class: "iconfont icon-quanxuan",
                                size: "mini",
                                onClick: _withModifiers(($event: any) => (_ctx.selAll(node, data)), ["stop"]),
                                disabled: _ctx.disabled
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('role.selectAll')), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick", "disabled"]),
                              _createVNode(_component_el_button, {
                                plain: "",
                                class: "iconfont icon-quxiao",
                                size: "mini",
                                onClick: _withModifiers(($event: any) => (_ctx.cancelAll(node, data)), ["stop"]),
                                disabled: _ctx.disabled
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('role.cancel')), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick", "disabled"])
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      : _createCommentVNode("", true),
                    (node.isLeaf && data.menuItemPermission!=[])
                      ? (_openBlock(), _createBlock(_component_el_select, {
                          key: data.id,
                          disabled: _ctx.disabled,
                          "validate-event": false,
                          "collapse-tags": "",
                          "collapse-tags-tooltip": "",
                          "max-collapse-tags": 8,
                          modelValue: _ctx.menuSelPermissionItems[data.id],
                          "onUpdate:modelValue": ($event: any) => ((_ctx.menuSelPermissionItems[data.id]) = $event),
                          multiple: "",
                          placeholder: "请选择"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.menuItemPermission, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.code,
                                label: item.name,
                                value: item.code
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["disabled", "modelValue", "onUpdate:modelValue"]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["data", "props", "onCheckChange", "onNodeClick", "onCurrentChange"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}